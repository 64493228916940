<template>
    <div class="vx-row py-2">
        <span class="block text-primary font-semibold leading-tight mb-2">
             {{ params.data.property_promotion.title }}
        </span>

        <p class="text-lg mt-1 leading-tight">
            {{ params.data.name }}
        </p>
    </div>
</template>

<script>
export default {
  name: 'CellRendererName',
  data: () => ({

  }),
  computed: {

  }
}
</script>

<style lang="scss" scpoped>

</style>
